import Vue from 'vue';
import Toasted from 'vue-toasted';
import App from './App.vue';
import router from './router';
import store from './store';
import './util/validation';
import './util/toTitleCase';
import './util/addDataId';
import '@/assets/materials.css';
import '@syncfusion/ej2-base/styles/bootstrap.css'; // https://cdn.syncfusion.com/ej2/ej2-buttons/styles/material.css?_ga=2.15167350.1122120130.1628080523-1966898429.1624275442
import '@syncfusion/ej2-buttons/styles/bootstrap.css';
import '@syncfusion/ej2-calendars/styles/bootstrap.css';
import '@syncfusion/ej2-dropdowns/styles/bootstrap.css';
import '@syncfusion/ej2-inputs/styles/bootstrap.css';
import '@syncfusion/ej2-navigations/styles/bootstrap.css';
import '@syncfusion/ej2-popups/styles/bootstrap.css';
import '@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
import '@syncfusion/ej2-vue-grids/styles/bootstrap.css';
import '@syncfusion/ej2-vue-richtexteditor/styles/bootstrap.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-vue-grids/styles/material.css';
import '@syncfusion/ej2-vue-richtexteditor/styles/material.css';
import '@/assets/styles.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'material-symbols';
import api from '@/util/api';
import { getCurrency, getNumberFromCurrency } from '@/util/getCurrency';
import { utcConvert } from '@/util/utcConvert';

// Global components imports
import Input from './components/general/validatedInputs/Input.vue';
import TextArea from './components/general/validatedInputs/TextArea.vue';
import ExpandableTextArea from './components/general/validatedInputs/ExpandableTextArea.vue';
import Pennies from './components/general/validatedInputs/Pennies';
import Dropdown from '@/components/general/validatedInputs/Dropdown.vue';
import DropdownWithSearchInput from '@/components/general/validatedInputs/DropdownWithSearchInput';
import UsStates from '@/components/general/validatedInputs/UsStates';
import ClientsDropDown from '@/components/general/validatedInputs/ClientsDropDown';
import Radios from '@/components/general/validatedInputs/Radios';
import Checkbox from '@/components/general/validatedInputs/Checkbox.vue';
import Checkboxes from '@/components/general/validatedInputs/Checkboxes.vue';
import ConfirmButton from '@/components/general/validatedInputs/ConfirmButton.vue';
import Toggle from '@/components/general/inputs/Toggle';

import { pageMetaMixin } from '@/mixins/pageMetaMixin';
import { vIfWorkableModalMixin } from '@/mixins/vIfWorkableModalMixin';
import Multiselect from 'vue-multiselect';
import VueSignaturePad from 'vue-signature-pad';
import { dryalex } from '@/mixins/dryalex';
import { disableAll } from '@/util/disableAll';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import '@/assets/mobex/custom_mobex.css';
import '@/assets/mobex/custom_mobex_vue.css';
import '@/assets/mobex/client_profile_common.css';
import '@/assets/mobex/client_overview.css';
import '@/assets/mobex/client_clinical.css';
import '@/assets/mobex/client_demographics.css';
import '@/assets/mobex/client_documents_forms.css';
import '@/assets/mobex/mobex_style.css';
import '@/assets/mobex/dashboard.css';
import '@/assets/mobex/counselors.css';
import '@/assets/mobex/telehealth_styles.css';
import '@/assets/mobex/topbar_styles.css';
import '@/assets/mobex/sidebar_styles.css';
import '@/assets/mobex/pagebody_styles.css';
import '@/assets/mobex/users_styles.css';
import '@/assets/mobex/vitals_page_styles.css';
import '@/assets/mobex/dropdownButtons.css';
import '@/assets/mobex/medications_styles.css';
import '@/assets/mobex/member_profiles_styles.css';
import '@/assets/mobex/dropdown_with_search_input.css';
import '@/assets/mobex/photos_style.css';
import '@/assets/mobex/link_sharing_styles.css';
import '@/assets/mobex/calendar_styles.css';
import '@/assets/mobex/add_member_styles.css';
import '@/assets/mobex/syncf_calendar_styles.css';
import '@/assets/mobex/company_information_styles.css';
import '@/assets/mobex/services_offered_styles.css';
import '@/assets/mobex/mood_history.css';

import { TooltipPlugin } from '@syncfusion/ej2-vue-popups';
import { aws_config } from '../src/config/cognito_config';
import { Amplify } from 'aws-amplify';
import Antd from 'ant-design-vue';

Vue.use(Antd);
Vue.use(TooltipPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSignaturePad);
Vue.use(Toasted, {
    position: 'bottom-center',
    duration: 4000,
    singleton: true,
});
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$cl = function (txt) {
    if (process.env.VUE_APP_AWS_ENV.substring(0, 3).toLowerCase() === 'dev') {
        console.log('Coming from this.$cl');
    }
    // logtoaws();
};
Vue.prototype.$clone_obj = function (obj) {
    return JSON.parse(JSON.stringify(obj));
};
(Vue.prototype.$email_alias = function (email, xtra = '') {
    let d = new Date();
    let randomizer = d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
    const email_pieces = email.split('@');
    if (xtra != '') {
        xtra = xtra + '_';
    }
    let temp_email = email_pieces[0] + '+' + xtra + randomizer + '@' + email_pieces[1];
    return temp_email;
}),
    (Vue.prototype.$getCurrency = getCurrency);
Vue.prototype.$getNumFromCurrency = getNumberFromCurrency;
Vue.prototype.$utcConvert = utcConvert;

Vue.component(Input.name, Input);
Vue.component(TextArea.name, TextArea);
Vue.component(ExpandableTextArea.name, ExpandableTextArea);
Vue.component(Pennies.name, Pennies);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownWithSearchInput.name, DropdownWithSearchInput);
Vue.component(UsStates.name, UsStates);
Vue.component(ClientsDropDown.name, ClientsDropDown);
Vue.component(Radios.name, Radios);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Checkboxes.name, Checkboxes);
Vue.component(ConfirmButton.name, ConfirmButton);
Vue.component(Toggle.name, Toggle);
Vue.component('Multiselect', Multiselect);

Vue.mixin(pageMetaMixin);
Vue.mixin(vIfWorkableModalMixin);
Vue.mixin(dryalex);

Vue.directive('disable-all', disableAll);

Amplify.configure(aws_config);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
