import Vue from 'vue';
import Vuex from 'vuex';
import filters from './modules/filters';
import scheduler from './modules/scheduler';
import recent from './modules/recent';
import modals from './modules/modals';
import clientOverview from './modules/clientOverview';
import newResourceModal from './modules/newResourceModal';
import progressNote from './modules/progressNote';
import eventEditor from './modules/eventEditor';

Vue.use(Vuex);

const buildId = window.localStorage.getItem('buildId');
const userStr = window.localStorage.getItem('cn-user');
const clientStr = window.localStorage.getItem('cn-client');
const googleToken = window.localStorage.getItem('cn-google-token');
const chime_meeting_id = window.localStorage.getItem('mobx_usr_chime_meeting_id');
const relatedClientsStr=window.localStorage.getItem('related-clients')
export default new Vuex.Store({
    state: {
        buildId: buildId ? JSON.parse(buildId) : "",
        showRefreshBN: false,
        user: userStr ? JSON.parse(userStr) : null,
        client: clientStr ? JSON.parse(clientStr) : null,
        current_client_id: 0,
        isAuthenticated: Boolean(userStr),
        initialized: false,
        oauth: googleToken ? JSON.parse(googleToken) : null,
        chime_meeting_id: (typeof chime_meeting_id === 'object') ? JSON.parse(chime_meeting_id) : null,
        chime_meeting_session: null,
        sideBartoggleValue:true,
        relatedClients: relatedClientsStr ? JSON.parse(relatedClientsStr) : null,
    },
    mutations: {
        updateUserDetails(state, payload) {
            if (state.user && payload.userId == state.user.id &&  state.user.user_detail) {
                const profilePhotoId = payload.updatedDetails.profile_photo_file_id || state.user.profile_photo_id;
        
                state.user.user_detail = {
                    ...state.user.user_detail,
                    ...payload.updatedDetails,
                    profile_photo_file_id: profilePhotoId,
                };
                state.user.name = `${state.user.user_detail.firstName} ${state.user.user_detail.lastName}`;
                state.user.role_id = state.user.user_detail.role;
                state.user.profile_photo_id = profilePhotoId;
        
                const storedUser = JSON.parse(localStorage.getItem('cn-user'));
                storedUser.user_detail = {
                    ...storedUser.user_detail,
                    ...payload.updatedDetails,
                    profile_photo_file_id: profilePhotoId,
                };  
                storedUser.name = `${state.user.user_detail.firstName} ${state.user.user_detail.lastName}`;
                storedUser.role_id = state.user.user_detail.role;
                storedUser.profile_photo_id = profilePhotoId;
    
                localStorage.setItem('cn-user', JSON.stringify(storedUser));
            }
        },
        persistUser(state, user) {
            const {google_token, ...user_data} = user;
            window.localStorage.setItem('cn-user', JSON.stringify(user_data));

            state.oauth = typeof google_token === 'string' ? JSON.parse(google_token) : null;
            window.localStorage.setItem('cn-google-token', google_token);
            state.user = user_data;
            let chimes = (typeof user_data.chime_meeting_id === 'string') || (typeof user_data.chime_meeting_id === 'object') ? user_data.chime_meeting_id : null;
            state.chime_meeting_id = chimes;
            window.localStorage.setItem('mobx_usr_chime_meeting_id', chimes);
            state.isAuthenticated = true;
        },
        persistRelatedClients(state, membersList) {
            window.localStorage.setItem('related-clients', JSON.stringify({membersList}));
            state.relatedClients = {membersList};
        },
        persistBuildId(state, buildId) {
            window.localStorage.setItem('buildId', JSON.parse(buildId));
            state.buildId = buildId;
        },
        setShowRefreshBN(state, showRefreshBN) {
            state.showRefreshBN = showRefreshBN;
        },
        persistClientId(state, current_client_id) {
            if (parseInt(current_client_id)) {
                state.current_client_id = parseInt(current_client_id);
                window.localStorage.setItem('last_client_id', parseInt(current_client_id))
            }
        },
        persist_chime_meeting_session(state, chime_meeting_session) {
            state.chime_meeting_session = chime_meeting_session;
        },
        persist_chime_meeting_id(state, chime_meeting_id) {
            if (chime_meeting_id === 'getlocalonreload') {
                chime_meeting_id = JSON.parse(window.localStorage.getItem('mobx_usr_chime_meeting_id'));
            }
            chime_meeting_id = (typeof chime_meeting_id === 'object') ? chime_meeting_id : null;

            state.chime_meeting_id = chime_meeting_id;
            if (chime_meeting_id === null) {
                window.localStorage.setItem('mobx_usr_chime_meeting_id', null);
            } else {
                window.localStorage.setItem('mobx_usr_chime_meeting_id', JSON.stringify(chime_meeting_id));
            }

        },
        clearUser(state) {
             Object.keys(localStorage)
                 .filter(key => key !== 'buildId')
                 .forEach(field => window.localStorage.removeItem(field))

            state.showRefreshBN = false;
            state.user = null;
            state.chime_meeting_id = null;
            state.client = null;
            state.initialized = false;
            state.isAuthenticated = false;
            state.initialized = false;
            state.oauth = null;
        },

        clearClient(state){
            window.localStorage.removeItem('cn-client');
            state.client = null;
        },
        clearRest(state){
            window.localStorage.clear()
            // clear the rest of the state
            state.user = null;
            state.client = null;
            state.current_client_id = 0;
            state.chime_meeting_session = null;
            state.isAuthenticated = false;
            state.initialized = false;
            state.oauth = null;

        },
        setCurrentClientId(state, {payload}){
            state.current_client_id = payload;
        },
        //

        //setOAUth Token
        setGoogleOAuthTokens(state, payload){
            window.localStorage.setItem('cn-google-token', JSON.stringify(payload));
            state.oauth = payload
        },

        clearGoogleTokens(state){
            window.localStorage.removeItem('cn-google-token');
            state.oauth = null
        },
        clearCurrentMeetingSession(state) {
            state.chime_meeting_id = null;
        },
    },
    getters: {
        getRelatedClients(state){
            return state.relatedClients.membersList
        },
        availablePages(state) {
            return state.user?.pages ? state.user.pages : [];
        },
        availablePageNames(state) {
            return state.user?.pages ? state.user.pages.map((page) => page.page) : [];
        },
        canCreateUsers(state) {
            const page = state.user?.pages.find((page) => page.page === 'users');

            if (!page) {
                return false;
            }

            return page.permissions.create;
        },
        companyId(state) {
            return state.user?.company_id;
        },

        menuPages(state) {
            if (!state.user) {
                return [];
            }

            const pages = [...state.user.pages];

            const allMenuPages = pages.filter((page) => page.menu_sort_id !== 0);

            const parentPages = {};

            for (const page of allMenuPages) {
                // If page has a parent
                if (page.parent_page) {
                    // If parent page already exists in parentPages object
                    if (parentPages[page.parent_page]) {
                        // Push this page to the children array of the parent page
                        parentPages[page.parent_page].children.push(page);
                    } else {
                        // Create a new key from the parent page and add children to it
                        parentPages[page.parent_page] = { children: [page] };
                    }
                } else {
                    // If page already exists in parentPages object
                    if (parentPages[page.page]) {
                        // Merge this page's info into the existing object
                        parentPages[page.page] = { ...parentPages[page.page], ...page };
                    } else {
                        // Create a new key on parentPages object and set it to this page
                        parentPages[page.page] = page;
                        // Add a children array to this page
                        parentPages[page.page].children = [];
                    }
                }
            }

            const menuPages = [];

            for (const pageName in parentPages) {
                menuPages.push(parentPages[pageName]);
            }

            return menuPages.sort((a, b) => a.menu_sort_id - b.menu_sort_id);
        },
        officeId(state) {
            return state.user?.office_id;
        },
        isClientLoggedIn(state) {
            // Identifies whether the user or client is logged in
            return !!state.client;
        },
        buildId(state) {
            return state.buildId;
        },
        refreshBN(state) {
            return state.showRefreshBN;
        }
    },
    actions: {
        /**
         * This function is utilized for firing off different actions after a user is authenticated
         * this is used for any set up needed after a user logs in that can't be solved
         * via a simple compute property
         */
        async initialize({ commit, state, dispatch }, payload) {
            try {
                await dispatch('recent/getRecent');

                // getting the list of active clients for the scheduler
                await dispatch('scheduler/getActiveClientList');
                await dispatch('scheduler/getServiceList');

                if (state.isAuthenticated == true) {
                    state.initialized = true;
                } else {
                    state.initialized = false;
                }
            } catch (error) {
                //
                if (state.isAuthenticated == true) {
                    state.initialized = true;
                } else {
                    state.initialized = false;
                }
            }
        }
    },
    modules: {
        filters,
        scheduler,
        recent,
        clientOverview,
        modals,
        newResourceModal,
        progressNote,
        eventEditor
    }
});
