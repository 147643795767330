/**
 * Convert base64 to buffer.
 * @param {*} base64 
 * @returns 
 */
export function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

export function saveFile (bytesBase64, mimeType, fileName) {
    var fileUrl = "data:" + mimeType + ";base64," + bytesBase64;
    fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
}

export function generateId() {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const randomCharacter =
        alphabet[Math.floor(Math.random() * alphabet.length)];
    return (
        Math.random().toString(36).substring(7) +
        Math.random().toString(36).substring(7) +
        randomCharacter
    );
}


/**
 * Removes and adds items back into a menu. This is function is useful for when a user has multiple dropdowns
 * and would like to prevent them from being selected.
 * 
 * Use this function with the Vue-Multislect dropdowns only.
 * @param {String | Object} newVal 
 * @param {String | Object} oldVal 
 * @param {String} propertyToMatchBy 
 * @param {Array} arrayOfOptions 
 * @returns 
 */
export function calibrateMenu(newVal, oldVal, propertyToMatchBy, arrayOfOptions){
  
    if (oldVal !== null && oldVal) {
        //add option back into array
        if(propertyToMatchBy){
            if (arrayOfOptions.some((option) => option?.[propertyToMatchBy] == oldVal?.[propertyToMatchBy]) == false) {
                arrayOfOptions.push(oldVal);
            }
        }else{
            if (arrayOfOptions.some((option) => option == oldVal) == false) {
                arrayOfOptions.push(oldVal);
            }
        }
    }
    //remove matching condition items from array
    arrayOfOptions = arrayOfOptions.filter((option) => {
        if(propertyToMatchBy){
            if (option?.[propertyToMatchBy] == newVal?.[propertyToMatchBy]) {
                return false;
            }
        }
        else{
            if (option == newVal) {
                return false;
            }
        }
        return true;
    });
    return arrayOfOptions
    
}


/**
 * Disables items. This is function is useful for when a user has multiple dropdowns
 * and would like to prevent them from being selected.
 *
 * Use this function with the Vue-Multislect dropdowns only.
 * @param {String | Object} newVal
 * @param {String | Object} oldVal
 * @param {String} propertyToMatchBy
 * @param {Array} arrayOfOptions
 * @returns
 */
export function calibrateMenuToDisabledStatus(newVal, oldVal, propertyToMatchBy, arrayOfOptions){
    if (oldVal !== null && oldVal) {
        //set option to enabled
        if(propertyToMatchBy){

            //Find the old option and reenble it
            let oldOptionIndex = arrayOfOptions.findIndex((option) => option?.[propertyToMatchBy] == oldVal?.[propertyToMatchBy]);
            if(oldOptionIndex > -1){
                arrayOfOptions[oldOptionIndex].$isDisabled = false;
            }

        }else{
            let oldOptionIndex = arrayOfOptions.findIndex((option) => option == oldVal);
            if(oldOptionIndex > -1){
                arrayOfOptions[oldOptionIndex].$isDisabled = false;
            }

        }
    }
    //disable matching condition items from array
    arrayOfOptions = arrayOfOptions.map((option) => {
        if(propertyToMatchBy){
            if (option?.[propertyToMatchBy] == newVal?.[propertyToMatchBy]) {
                option.$isDisabled = true;
            }
        }
        else{
            if (option == newVal) {
                option.$isDisabled = true;
            }
        }
        return option;
    })
    return arrayOfOptions

}



/**
 * Debounce
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function debounce (fn, delay) {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, delay)
    }
  }

  export function dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}


export function set_prefs(key, data) {
    let page_prefs = {}
    if (window.localStorage.getItem('page_prefs')) {
        page_prefs = JSON.parse(window.localStorage.getItem('page_prefs'))
    }
    page_prefs[key] = data
    window.localStorage.setItem('page_prefs', JSON.stringify(page_prefs))
}

export function get_prefs(key) {
    let result = {}
    let page_prefs = {}
    if (window.localStorage.getItem('page_prefs')) {
        page_prefs = JSON.parse(window.localStorage.getItem('page_prefs'))
        if (page_prefs[key]) {
            result = page_prefs[key]
        }
    } else {
        set_prefs(key,{})
    }
    return result
}

export function formatPortalName(name) {
    if (!name) return '';

    name = name.trim();

    const specialCases = {
        'ssg': 'Scan Save & Go',
        'kiosk': 'MobexHealth Community'
    };

    if (specialCases[name.toLowerCase()]) {
        return specialCases[name.toLowerCase()];
    }

    // "Mobex Health" or "Mobex-Health" -> "MobexHealth"
    name = name.replace(/mobex[-\s]health/gi, 'MobexHealth');

    // "Star Kid" and "Star-Kid" -> "STAR Kids"
    name = name.replace(/\bstar[-\s]kid\b/gi, (match) => 
        match.includes('-') ? 'STAR Kids' : 'STAR Kids'
    );

    name = name.replace(/-/g, ' ');

    return name;
}


